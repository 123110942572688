<template>
	<div class="grid">
		<div class="col-12">
			<Card>
                <template #title>
                    {{title}}
                </template>
                <template #content>
					<!-- Toolbar -->
					<Toolbar class="mb-4">
						<template #start>
							<Button label="Tambah" icon="pi pi-plus" class="p-button-primary mr-2" @click="openNew" />
							<Button label="Hapus" icon="pi pi-trash" class="p-button-danger" 
								@click="openDelete('multi')" :disabled="!dataTable.selectedData || !dataTable.selectedData.length" />
						</template>
					</Toolbar>

					<!-- Data Table -->
					<DataTable class="p-datatable-gridlines p-datatable-sm" filterDisplay="menu" :rowHover="true" :lazy="true" :paginator="true" 
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport" 
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
						v-model:filters="dataTable.params.filters"
						v-model:selection="dataTable.selectedData" dataKey="id"
						:rowsPerPageOptions="dataTable.rows_option"
						:value="dataTable.data"
						:rows="dataTable.params.rows" 
						:totalRecords="dataTable.totalRecords" 
						:loading="dataTable.loading" 
						@page="loadData($event)" @sort="loadData($event)">
						<template #header>
							<div class="flex justify-content-between flex-column sm:flex-row">
								<div></div>
								<span class="p-input-icon-left mb-2">
									<i class="pi pi-search" />
									<InputText v-model="dataTable.params.filters['global'].value" @keydown.enter="loadData()" :placeholder="dataTable.global_placeholder" v-tooltip.top.focus="'Press Enter'" style="width: 100%"/>
								</span>
							</div>
						</template>
						<template #empty>
							<div class="p-text-center">No data found.</div>
						</template>
						<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
						<Column field="roles" header="Role" :sortable="false" :showFilterMatchModes="false">
							<template #body="{data}">
								{{data.roles.name}}
							</template>
							<template #filter="{filterModel,filterCallback}">
								<MultiSelect v-model="filterModel.value" :options="relations.roles" @change="filterCallback()" optionValue="id" optionLabel="name" display="chip" :filter="true" placeholder="Select" />
							</template>
						</Column>
						<Column field="action" header="Action" :sortable="false" class="p-text-nowrap">
							<template #body="{data}">
								<Button @click="openEdit(data)" v-tooltip.top="'Edit'" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-primary mr-2" />
								<Button @click="openDelete('single', data)" v-tooltip.top="'Hapus'" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" />
							</template>
						</Column>
					</DataTable>

					<!-- Form -->
					<Dialog v-model:visible="dialog.formData" :header="formDataTitle" :style="{width: '800px'}" :modal="true" :dismissableMask="true" class="p-fluid">
						<div class="field">
							<label>Role</label>
							<Dropdown v-model="dataForm.role_id" :options="relations.roles"
								optionValue="id"
								optionLabel="name"
								:class="{ 'p-invalid': dataForm.errors.has('role_id') }" />
							<small class="p-error" v-show="dataForm.errors.has('role_id')">
								{{ dataForm.errors.get('role_id') }}
							</small>
						</div>
                        <div class="field">
							<label>Content</label>
							<Editor
								v-model="dataForm.content"
								:class="{ 'p-invalid': dataForm.errors.has('content') }"
								:api-key="$tinymce_api_key"
								:init="{
									height: 400,
									plugins: 'print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars fullscreen image link media codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
									menubar: 'file edit view insert format tools table help',
									toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | image media link anchor codesample | ltr rtl',
									valid_children: '+body[style]'
								}"
							/>
							<small class="p-error" v-show="dataForm.errors.has('content')">
								{{ dataForm.errors.get('content') }}
							</small>
						</div>

						<template #footer>
							<Button label="Save" @click="submitData" :disabled="dataForm.busy" :icon="(dataForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-text" style="float:right" />
							<Button label="Cancel" @click="dialog.formData = false" icon="pi pi-times" class="p-button-text" />
						</template>
					</Dialog>

					<!-- Delete Confirmation -->
					<Dialog v-model:visible="dialog.deleteData" header="Confirmation" :style="{width: '350px'}" :modal="true" :dismissableMask="true" class="p-fluid">
						<div class="confirmation-content">
							<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
							<span>Do you want to delete this record?</span>
						</div>
						<template #footer>
							<Button label="No" icon="pi pi-times" @click="dialog.deleteData = false" class="p-button-text"/>
							<Button label="Yes" icon="pi pi-check" @click="deleteData" class="p-button-text" autofocus />
						</template>
					</Dialog>
				</template>
			</Card>

		</div>
	</div>

</template>

<script>
export default {
	data() {
		return {
			title: 'Role Dashbaord',
			api: '/api/role-dashboard',
			dataForm: new this.$FormAuth({
				mode: null,
				id: null,
				role_id: null,
				content: null,
			}),
			relations: {
				roles: null,
			},
			dataTable: {
				data: [],
				loading: false,
				global_placeholder: 'Role',
				totalRecords: 0,
				rows_option: [10, 25, 50, 100],
				selectedData: [],
				params: {
					filters: {
						global: {value: null},
						roles: {value: null},
					},
					rows: 10, //per_page
					page: 0,
					sortField: null,
					sortOrder: null,
				},
			},
			dialog: {
				formData: false,
				deleteData: false,
			},
			formDataTitle: null,
		}
	},
	methods: {
		loadData(event = null){
			this.$Progress.start();
			this.dataTable.loading = true;
			(event) ? this.dataTable.params = event : null;
			this.$httpAuth.get(this.api, {
				params: {
					rows: this.dataTable.params.rows,
					sortField: this.dataTable.params.sortField,
					sortOrder: this.dataTable.params.sortOrder,
					filters: this.dataTable.params.filters,
					page: this.dataTable.params.page + 1,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.dataTable.loading = false;
				this.dataTable.data = response.data.data;
				this.dataTable.totalRecords = response.data.total;
			})
			.catch((error) => {
				this.$Progress.fail();
				this.dataTable.loading = false;
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},

		getRoles(){
            this.$httpAuth.get(this.api + '/get/roles')
            .then((response) => {
                this.relations.roles = response.data;
            })
            .catch((error) => {
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },

		openNew() {
			this.resetForm();
			this.dataForm.mode = 'add';
			this.formDataTitle = 'Tambah '+this.title;
			this.dialog.formData = true;
		},
		openEdit(data){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/' + data.id + '/edit')
			.then((response) => {
				this.$Progress.finish();
				this.resetForm();
				this.dataForm.fill(response.data);
				this.dataForm.mode = 'edit';
				this.formDataTitle = 'Edit '+this.title;
				this.dialog.formData = true;
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		openDelete(mode = null, data = null){
			if(mode == 'single'){
				this.dataTable.selectedData = [];
				this.dataTable.selectedData.push(data);
			}
			this.dialog.deleteData = true;
		},
		resetForm(){
			this.dataForm.clear();
			this.dataForm.reset();
		},
		submitData(){
			this.$Progress.start();
			if(this.dataForm.mode == 'add'){
				this.dataForm.post(this.api)
				.then((response) => {
					this.$Progress.finish();
					this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
					this.dialog.formData = false;
					this.loadData();
				})
				.catch((error) => {
					this.$Progress.fail();
					this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
				});
			} else if(this.dataForm.mode == 'edit') {
				this.dataForm.put(this.api + '/' + this.dataForm.id)
				.then((response) => {
					this.$Progress.finish();
					this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
					this.dialog.formData = false;
					this.loadData();
				})
				.catch((error) => {
					this.$Progress.fail();
					this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
				});
			}
		},
		deleteData() {
			this.$Progress.start();
			this.dialog.deleteData = false;
			const selectedId = this.dataTable.selectedData.map(value => value.id);
			this.$httpAuth.delete(this.api + '/delete', {
				params: {
					id: selectedId
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.loadData();
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
	},
	watch: {
		'dataTable.params.filters': function(){
			this.loadData();
		},
    },
	created(){
		this.$e.emit('updateTitle', this.title);
	},
	mounted() {
		this.loadData();
		this.getRoles();
	},
}
</script>